import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { SlClose } from 'react-icons/sl';
import filterIcon from '../../../images/filter.ico';
import { setWorking } from '../../../redux/reducers/workingSlice';
import { setMessage } from '../../../redux/reducers/showMessageWindowSlice';
import { execProcedure } from '../../../services/functions/server_functions/functions';
import { meseci } from '../../../data/meseci';
import { customStyles } from '../../../assets/selectCustomStyles';
import './RasknjizavanjeGoriva.css';

const RasknjizavanjeGoriva = () => {
  const [showFilter, setShowFilter] = useState(true);
  const [reportFilterCriteria, setReportFilterCriteria] = useState({
    mesec: new Date().getMonth() + 1,
    godina: new Date().getFullYear(),
    masina: { label: '-----', value: 0 },
    operacija: { label: '-----', value: 0 },
  });
  const [masina, setMasina] = useState([]);
  const [operacija, setOperacija] = useState([]);
  const [rasknjizavanjeGoriva, setRasknjizavanjeGoriva] = useState([]);
  const [ukupno, setUkupno] = useState({ povrsina_ukupno: 0, gorivo_ukupno: 0, gorivo_prosek: 0 });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ojUser = useSelector((state) => state.user);

  useEffect(() => {
    setReportFilterCriteria({ ...reportFilterCriteria, masina: { label: '-----', value: 0 }, operacija: { label: '-----', value: 0 } });
    fetchMasina();
    fetchOperacija();
  }, [reportFilterCriteria.godina, reportFilterCriteria.mesec])

  useEffect(() => {
    // setReportFilterCriteria({ ...reportFilterCriteria, masina: { label: '-----', value: 0 }, operacija: { label: '-----', value: 0 } });
    fetchMasina();
    fetchOperacija();
  }, [reportFilterCriteria.masina, reportFilterCriteria.operacija])

  const fetchMasina = async () => {
    try {
      dispatch(setWorking(true));
      const masinaData = await execProcedure('allGet', 'reportRasknjizavanjeGorivaMasine', {
        godina: reportFilterCriteria.godina,
        mesec: reportFilterCriteria.mesec,
        oj: ojUser.authUser.oj_id,
        operacija: reportFilterCriteria.operacija.value
      });
      setMasina(masinaData.data);
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  const fetchOperacija = async () => {
    try {
      dispatch(setWorking(true));
      const operacijaData = await execProcedure('allGet', 'reportRasknjizavanjeGorivaOperacije', {
        godina: reportFilterCriteria.godina,
        mesec: reportFilterCriteria.mesec,
        oj: ojUser.authUser.oj_id,
        masina: reportFilterCriteria.masina.value
      });
      setOperacija(operacijaData.data);
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  const handleFilter = async () => {
    try {
      dispatch(setWorking(true));
      const rasknjizavanjeGorivaData = await execProcedure('allGet', 'reportRasknjizavanjeGoriva', {
        godina: reportFilterCriteria.godina,
        mesec: reportFilterCriteria.mesec,
        masina: reportFilterCriteria.masina.value,
        operacija: reportFilterCriteria.operacija.value,
        oj: ojUser.authUser.oj_id
      });
      setRasknjizavanjeGoriva(rasknjizavanjeGorivaData.data);
      let povrsina_u = 0, gorivo_u = 0;

      rasknjizavanjeGorivaData.data?.forEach((r) => {
        povrsina_u += r.parcela_uradjeno;
        gorivo_u += r.gorivo_ukupno;
      });
      setUkupno({ povrsina_ukupno: povrsina_u, gorivo_ukupno: gorivo_u, gorivo_prosek: povrsina_u === 0 ? 0 : gorivo_u / povrsina_u });
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  return (
    <div className='radni-nalog-container'>
      <div className='radni-nalog-table-form'>
        <div className="radni-nalog-table-form-header">Rasknjižavanje goriva<SlClose className='close-button' onClick={() => navigate('/main/landingpage')} /></div>
        <div className='rasknjizavanje-goriva-filter' style={{ display: showFilter ? 'flex' : 'none' }}>
          <div className="rasknjizavanje-goriva-filter-left">
            <div className="godina-i-mesec">
              <div className="input-field">
                <label htmlFor="">Godina</label>
                <select value={reportFilterCriteria.godina} onChange={(e) => {
                  setReportFilterCriteria({ ...reportFilterCriteria, godina: e.target.value, masina: { label: '-----', value: 0 }, operacija: { label: '-----', value: 0 } });
                }}>
                  <option key={1} value={2025}>2025</option>
                  <option key={2} value={2024}>2024</option>
                  <option key={3} value={2023}>2023</option>
                </select>
              </div>
              <div className="input-field">
                <label htmlFor="">Mesec</label>
                <select value={reportFilterCriteria.mesec} onChange={(e) => {
                  setReportFilterCriteria({ ...reportFilterCriteria, mesec: e.target.value, masina: { label: '-----', value: 0 }, operacija: { label: '-----', value: 0 } });
                }}>
                  {meseci.map((m) => {
                    return (
                      <option key={m.id} value={m.id}>{m.naziv}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className="masina-i-operacija">
              <div className="input-field">
                <label htmlFor="">Mašina</label>
                <Select className='select-repromaterijal'
                  value={{ label: reportFilterCriteria.masina.label, value: reportFilterCriteria.masina.value }}
                  onChange={(e) => {
                    if (e === null) {
                      setReportFilterCriteria({ ...reportFilterCriteria, masina: { label: '-----', value: 0 } });
                      setRasknjizavanjeGoriva([]);
                      setUkupno({ povrsina_ukupno: 0, gorivo_ukupno: 0, gorivo_prosek: 0 })
                    } else {
                      setReportFilterCriteria({ ...reportFilterCriteria, masina: e });
                      // fetchOperacija();
                    }
                  }}
                  options={masina}
                  placeholder={''}
                  noOptionsMessage={() => 'Nema podataka.'}
                  isClearable
                  isSearchable
                  styles={customStyles}
                />
              </div>
              <div className="input-field">
                <label htmlFor="">Operacija</label>
                <Select className='select-repromaterijal'
                  value={{ label: reportFilterCriteria.operacija.label, value: reportFilterCriteria.operacija.value }}
                  onChange={(e) => {
                    if (e === null) {
                      setReportFilterCriteria({ ...reportFilterCriteria, operacija: { label: '-----', value: 0 } });
                      setRasknjizavanjeGoriva([]);
                      setUkupno({ povrsina_ukupno: 0, gorivo_ukupno: 0, gorivo_prosek: 0 })
                    } else {
                      setReportFilterCriteria({ ...reportFilterCriteria, operacija: e });
                      // fetchMasina();
                    }
                  }}
                  options={operacija}
                  placeholder={''}
                  noOptionsMessage={() => 'Nema podataka.'}
                  isClearable
                  isSearchable
                  styles={customStyles}
                />
              </div>
            </div>
          </div>
          <div className="rasknjizavanje-repromaterijala-filter-right">
            <button onClick={() => {
              handleFilter();
            }
            }>Filtriraj</button>
          </div>
        </div>
        <div className="radni-nalog-table-form-main">
          <img className='filter-icon' src={filterIcon} onClick={() => { setShowFilter(!showFilter) }} />
          <div className="radni-nalog-table-form-main-table-wrapper">
            <table className='tabela'>
              <thead>
                <tr>
                  <th>Datum</th>
                  <th>Nalog</th>
                  <th>Parcela</th>
                  <th>Kultura</th>
                  <th>Površina</th>
                  <th>Gorivo</th>
                  <th>Prosečna potrošnja</th>
                </tr>
              </thead>
              <tbody>
                {rasknjizavanjeGoriva?.map((data, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td>{data.datum}</td>
                        <td>{data.parcela_id}</td>
                        <td>{data.parcela_naziv}</td>
                        <td>{data.kultura_naziv}</td>
                        <td style={{ textAlign: 'right' }}>{(data.parcela_uradjeno)?.toFixed(2)}</td>
                        <td style={{ textAlign: 'right' }}>{(data.gorivo_ukupno)?.toFixed(2)}</td>
                        <td style={{ textAlign: 'right' }}>{(data.prosecna_potrosnja)?.toFixed(2)}</td>
                      </tr>
                    </>
                  )
                })
                }
              </tbody>
              <tfoot style={{ backgroundColor: 'crimson', color: 'white', fontWeight: '500' }}>
                <tr>
                  <td style={{ textAlign: 'right' }} colSpan={'4'}>{'UKUPNO:'}</td>
                  <td style={{ textAlign: 'right' }}>{(ukupno?.povrsina_ukupno).toFixed(2)}</td>
                  <td style={{ textAlign: 'right' }}>{(ukupno?.gorivo_ukupno).toFixed(2)}</td>
                  <td style={{ textAlign: 'right' }}>{(ukupno?.gorivo_prosek).toFixed(2)}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className='number-of-rows'>Broj redova: {rasknjizavanjeGoriva.length}</div>
        </div>
      </div >
    </div>
  )
}

export default RasknjizavanjeGoriva;