import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import * as XLSX from "xlsx";
import { SlClose } from "react-icons/sl";
import filterIcon from "../../../images/filter.ico";
import { setWorking } from "../../../redux/reducers/workingSlice";
import { setMessage } from "../../../redux/reducers/showMessageWindowSlice";
import { execProcedure, selectStatementData } from "../../../services/functions/server_functions/functions";
import { meseci } from "../../../data/meseci";
import { customStyles } from "../../../assets/selectCustomStyles";
import "./UtrosakHemije.css";

const UtrosakHemije = () => {
  // const [selectedOj, setSelectedOj] = useState({ oj: 0 });
  const [oj, setOj] = useState([]);
  const [parcela, setParcela] = useState([]);



  const [showFilter, setShowFilter] = useState(true);
  const [reportFilterCriteria, setReportFilterCriteria] = useState({
    oj: 852, //ojUser.authUser.oj_id,
    datumOd: '',//(new Date()).getDate(),
    datumDo: '',
    parcela: null,
  });

  const [rasknjizavanjeRepromaterijala, setRasknjizavanjeRepromaterija] = useState([]);
  const [ukupno, setUkupno] = useState({
    povrsina_ukupno: 0,
    repromaterijal_ukupno: 0,
    repromaterijal_prosek: 0,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ojUser = useSelector((state) => state.user);

  useEffect(() => {
    const fetchOJ = async () => {
      const ojData = await selectStatementData(`SELECT oj.id, oj.naziv FROM organizaciona_jedinica oj`);
      setOj(ojData.data);
      setReportFilterCriteria({
        ...reportFilterCriteria,
        oj: ojUser.authUser.oj_id
      });
    }
    fetchOJ();
    fetchParcele();
  }, []);

  useEffect(() => {
    fetchParcele();
  }, [reportFilterCriteria.oj])

  const fetchParcele = async () => {
    try {
      dispatch(setWorking(true));
      const parcelaData = await selectStatementData(`SELECT p.id, p.naziv FROM parcela p WHERE p.oj_id = ${reportFilterCriteria.oj} AND p.kultura_id <> 0`);
      setParcela(parcelaData.data);
    } catch (error) {
      dispatch(
        setMessage({
          msgText: "Desila se sistemska greška",
          icon: "warning",
          showHide: true,
        })
      );
    } finally {
      dispatch(setWorking(false));
    }
  };

  const handleFilter = async () => {
    try {
      dispatch(setWorking(true));
      if (reportFilterCriteria.datumOd === '' || reportFilterCriteria.datumDo === '') {
        dispatch(
          setMessage({
            msgText: "Nije unet vremenski interval",
            icon: "warning",
            showHide: true,
          })
        );
        return;
      }
      console.log(reportFilterCriteria);
      const reportUtrosakHemijeData = await execProcedure(
        "getUtrosakHemije",
        "reportUtrosakHemije", {});

      //   {
      //     godina: reportFilterCriteria.godina,
      //     mesec: reportFilterCriteria.mesec,
      //     repromaterijal: reportFilterCriteria.repromaterijal.value,
      //     oj: ojUser.authUser.oj_id,
      //   }
      // );
      // setRasknjizavanjeRepromaterija(rasknjizavanjeRepromaterijalaData.data);
      // let povrsina_u = 0,
      //   repromaterijal_u = 0;

      // rasknjizavanjeRepromaterijalaData.data?.forEach((r) => {
      //   povrsina_u += r.parcela_uradjeno;
      //   repromaterijal_u += r.repromaterijal_ukupno;
      // });
      // setUkupno({
      //   povrsina_ukupno: povrsina_u,
      //   repromaterijal_ukupno: repromaterijal_u,
      //   repromaterijal_prosek:
      //     povrsina_u === 0 ? 0 : repromaterijal_u / povrsina_u,
      // });
    } catch (error) {
      dispatch(
        setMessage({
          msgText: "Desila se sistemska greška",
          icon: "warning",
          showHide: true,
        })
      );
    } finally {
      dispatch(setWorking(false));
    }
  };

  const handleSravnjenjeSaMagacinom = async () => {
    // if (repromaterijal.length === 0) {
    //   if (1 === 0) {
    //     dispatch(setMessage({ msgText: `Nema podataka za export za mesec ${meseci[reportFilterCriteria.mesec - 1].naziv}.`, icon: "info", showHide: true }));
    //     dispatch(setWorking(false));
    //     return;
    //   }

    //   try {
    //     dispatch(setWorking(true));
    //     const workbook = XLSX.utils.book_new();
    //     for (let i = 0; i < repromaterijal.length; i++) {
    //       const data = await getDataForExcel(repromaterijal[i].value);
    //       const worksheet = XLSX.utils.json_to_sheet(data, {
    //         skipHeader: true,
    //         origin: "B3"
    //       });
    //       XLSX.utils.sheet_add_aoa(
    //         worksheet,
    //         [
    //           [
    //             "Datum R. naloga",
    //             "ID parcele",
    //             "Naziv parcele",
    //             "ID repromaterijala",
    //             "Naziv repromaterijala",
    //             "JM",
    //             "Količina",
    //           ],
    //         ],
    //         {
    //           origin: "B2",
    //         }
    //       );

    //       let range = XLSX.utils.decode_range(worksheet['!ref']);
    //       let noRows = range.e.r; //let noCols = range.e.c;

    //       XLSX.utils.sheet_add_aoa(
    //         worksheet,
    //         [
    //           ["UKUPNO:"],
    //         ],
    //         {
    //           origin: `G${noRows + 2}`,
    //         }
    //       );

    //       XLSX.utils.sheet_set_array_formula(worksheet, `H${noRows + 2}`, `SUM(H2:H${noRows + 1})`);

    //       XLSX.utils.book_append_sheet(
    //         workbook,
    //         worksheet,
    //         `${repromaterijal[i].value}`
    //       );

    //       worksheet['!cols'] = [{ wch: 8 }, { wch: 14 }, { wch: 10 }, { wch: 20 }, { wch: 16 }, { wch: 30 }, { wch: 8 }, { wch: 12 },];

    //       worksheet['B2'].s = {
    //         fill: {
    //           type: 'pattern',
    //           pattern: "solid", // none / solid
    //           fgColor: { argb: "FF1c4587" },
    //           bgColor: { argb: "FF1c4587" }
    //         }
    //       }
    //     }

    //     XLSX.writeFile(
    //       workbook,
    //       `Sravnjenje magacina za ${meseci[reportFilterCriteria.mesec - 1].naziv
    //       }.xlsx`
    //     );
    //   } catch (error) {
    //     dispatch(
    //       setMessage({
    //         msgText: "Desila se sistemska greška",
    //         icon: "warning",
    //         showHide: true,
    //       })
    //     );
    //   } finally {
    //     dispatch(setWorking(false));
    //   }
    // }
  };

  const getDataForExcel = async (repromaterijal) => {
    const rasknjizavanjeRepromaterijalaData = await execProcedure(
      "allGet",
      "reportSravnjenjeRepromaterijalaSaMagacinom",
      {
        godina: reportFilterCriteria.godina,
        mesec: reportFilterCriteria.mesec,
        oj: ojUser.authUser.oj_id,
        repromaterijal: repromaterijal,
      }
    );
    return await rasknjizavanjeRepromaterijalaData.data;
  };

  const saveToExcel = async (workbook) => {
    XLSX.writeFile(
      workbook,
      `Sravnjenje magacina za ${meseci[reportFilterCriteria.mesec - 1].naziv
      }.xlsx`
    );
  };

  return (
    <div className="radni-nalog-container">
      <div className="radni-nalog-table-form">
        <div className="radni-nalog-table-form-header">
          Utrošak hemije
          <SlClose
            className="close-button"
            onClick={() => navigate("/main/landingpage")}
          />
        </div>
        <div
          className="rasknjizavanje-repromaterijala-filter"
          style={{ display: showFilter ? "flex" : "none" }}
        >
          <div className="rasknjizavanje-repromaterijala-filter-left">
            <div className="godina-i-mesec">
              {/* <div className="input-field">
                <label htmlFor="">Godina</label>
                <select value={reportFilterCriteria.godina}
                  onChange={(e) => {
                    setReportFilterCriteria({
                      ...reportFilterCriteria,
                      godina: e.target.value,
                      repromaterijal: { label: "", value: 0 },
                    });
                    fetchRepromaterijal();
                  }}
                >
                  <option key={1} value={2023}>
                    2023
                  </option>
                  <option key={2} value={2024}>
                    2024
                  </option>
                </select>
              </div> */}

              <div className='box-1'>
                <div className="input-field">
                  <label htmlFor="">OJ</label>
                  <select
                    value={reportFilterCriteria.oj}
                    onChange={(e) => {
                      setReportFilterCriteria({
                        ...reportFilterCriteria,
                        oj: e.target.value,
                      });
                      // fetchParcele();
                    }}
                  >
                    {oj && oj.map((o) => {
                      return (
                        <option key={o.id} value={o.id}>
                          {o.naziv}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="input-field">
                  <label htmlFor="">Od datuma</label>
                  <input type="date"
                    value={reportFilterCriteria.datumOd}
                    onChange={(e) => {
                      setReportFilterCriteria({
                        ...reportFilterCriteria,
                        datumOd: e.target.value
                      });
                    }}
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="">Do datuma</label>
                  <input type="date"
                    value={reportFilterCriteria.datumDo}
                    onChange={(e) => {
                      setReportFilterCriteria({
                        ...reportFilterCriteria,
                        datumDo: e.target.value
                      });
                    }}
                  />
                </div>
              </div>

            </div>
            <div className="input-field">
              <label htmlFor="">Parcele</label>
              <select
                className="select-repromaterijal"
                value={parcela.id}
                onChange={(e) => {
                  setReportFilterCriteria({
                    ...reportFilterCriteria,
                    parcela: e.target.value,
                  });
                }
                }>
                {parcela && parcela.map((p) => {
                  return (
                    <option key={p.id} value={p.id}>
                      {p.id}{' '}{p.naziv}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="rasknjizavanje-repromaterijala-filter-right">
            <button
              onClick={() => {
                handleFilter();
              }}
            >
              Filtriraj
            </button>
          </div>
        </div>
        <div className="radni-nalog-table-form-main">
          <img
            className="filter-icon"
            src={filterIcon}
            onClick={() => {
              setShowFilter(!showFilter);
            }}
          />
          <div className="radni-nalog-table-form-main-table-wrapper">
            <table className="tabela">
              <thead>
                <tr>
                  <th>Datum</th>
                  <th>Nalog</th>
                  <th>Parcela</th>
                  <th>Kultura</th>
                  <th>Površina</th>
                  <th>Repromaterijal</th>
                  <th>Prosečna potrošnja</th>
                </tr>
              </thead>
              <tbody>
                {rasknjizavanjeRepromaterijala?.map((data, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td>{data.datum}</td>
                        <td>{data.parcela_id}</td>
                        <td>{data.parcela_naziv}</td>
                        <td>{data.kultura_naziv}</td>
                        <td style={{ textAlign: "right" }}>
                          {data.parcela_uradjeno?.toFixed(2)}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {data.repromaterijal_ukupno?.toFixed(2)}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {data.prosecna_potrosnja?.toFixed(2)}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
              <tfoot
                style={{
                  backgroundColor: "crimson",
                  color: "white",
                  fontWeight: "500",
                }}
              >
                <tr>
                  <td style={{ textAlign: "right" }} colSpan={"4"}>
                    {"UKUPNO:"}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {(ukupno?.povrsina_ukupno).toFixed(2)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {(ukupno?.repromaterijal_ukupno).toFixed(2)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {(ukupno?.repromaterijal_prosek).toFixed(2)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="number-of-rows">
            Broj redova: {rasknjizavanjeRepromaterijala.length}
          </div>
        </div>
        <div className="radni-nalog-table-form-footer">
          <div className="radni-nalog-table-form-footer-right">
            <button
              className="rasknjizavanje-repromaterijala-export-to-excel"
              onClick={handleSravnjenjeSaMagacinom}
            >
              Exportuj podatke
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UtrosakHemije;
